<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <validation-observer ref="registerInvoiceForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <b-col cols="4">
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary tagesbericht-logo">
                        <span>Tempo</span>Ratio
                      </h3>
                    </div>
                    <p class="card-text mb-25">Carl-Benz-Straße 3</p>
                    <p class="card-text mb-25">68723 Schwetzingen</p>
                    <p class="card-text mb-25">Tel.: 06202 / 859349-0</p>
                    <p class="card-text mb-25">Fax: 06202 / 859349-9</p>
                    <p class="card-text mb-25">Email: quality@temporatio.de</p>
                    <p class="card-text mb-25">Webseite: www.temporatio.de</p>
                  </b-col>

                  <!-- Header: Right Content -->
                  <!-- Header: Right Content -->
                  <b-col
                    cols="7"
                    lg="6"
                    class="invoice-number-date mt-md-0 mt-2"
                  >
                    <div
                      v-if="
                        orderData.Kunde.Kundennummer ||
                        manualClient.Kundennummer
                      "
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1">Kundennummer:</h5>
                      <h5 class="w-100 text-right">
                        #{{
                          orderData.Kunde.Kundennummer ||
                          manualClient.Kundennummer
                        }}
                      </h5>
                    </div>
                    <div
                      v-if="!invoiceData.Manuelle_Rechnung"
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1">Auftragsnummer:</h5>
                      <h5 class="w-100 text-right">
                        #{{ orderData.Auftragsnummer }}
                      </h5>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1 w-100">Rechnungsnummer:</h5>
                      <h5 class="w-100 text-right">
                        #{{ invoiceData.Rechnungsnummer }}
                      </h5>
                      <!-- <validation-provider
                        #default="{ errors }"
                        name="Rechnungsnummer"
                        vid="rechnungsnummer"
                        rules="required|min:5"
                        class="mw-100 w-100"
                      >
                        <b-input-group class="disabled mw-100">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="HashIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="invoice-data-id"
                            v-model="invoiceData.Rechnungsnummer"
                            class="pl-1 text-right"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="invoiceIdNotUnique" class="text-danger"
                          >Rechnungsnummer bereits vergeben</small
                        >
                      </validation-provider> -->
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1 w-100">Rechnungsdatum:</h5>
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled mw-100"
                      >
                        <b-form-datepicker
                          id="rechnungsdatum"
                          v-model="invoiceData.Rechnungsdatum"
                          class="text-right"
                          v-bind="{
                            labelPrevDecade: 'Vorheriges Jahrzehnt',
                            labelPrevYear: 'Vorheriges Jahr',
                            labelPrevMonth: 'Vorheriger Monat',
                            labelCurrentMonth: 'Aktueller Monat',
                            labelNextMonth: 'Nächster Monat',
                            labelNextYear: 'Nächstes Jahr',
                            labelNextDecade: 'Nächstes Jahrzehnt',
                            labelToday: 'Heute',
                            labelSelected: 'Ausgewähltes Datum',
                            labelNoDateSelected: 'Kein Datum gewählt',
                            labelCalendar: 'Kalender',
                            labelNav: 'Kalendernavigation',
                            labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                          }"
                          :start-weekday="1"
                          :date-format-options="{
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          }"
                          show-decade-nav
                          locale="de"
                          aria-controls="startdatum"
                          placeholder="Datum"
                        />
                      </b-input-group>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1 w-100">Fälligkeitsdatum:</h5>
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled mw-100"
                      >
                        <b-form-datepicker
                          id="faelligkeitsdatum"
                          v-model="invoiceData.Faelligkeitsdatum"
                          class="text-right"
                          v-bind="{
                            labelPrevDecade: 'Vorheriges Jahrzehnt',
                            labelPrevYear: 'Vorheriges Jahr',
                            labelPrevMonth: 'Vorheriger Monat',
                            labelCurrentMonth: 'Aktueller Monat',
                            labelNextMonth: 'Nächster Monat',
                            labelNextYear: 'Nächstes Jahr',
                            labelNextDecade: 'Nächstes Jahrzehnt',
                            labelToday: 'Heute',
                            labelSelected: 'Ausgewähltes Datum',
                            labelNoDateSelected: 'Kein Datum gewählt',
                            labelCalendar: 'Kalender',
                            labelNav: 'Kalendernavigation',
                            labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                          }"
                          :start-weekday="1"
                          :date-format-options="{
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          }"
                          show-decade-nav
                          locale="de"
                          aria-controls="startdatum"
                          placeholder="Datum"
                        />
                      </b-input-group>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="mr-1 w-100">Abrechnungszeitraum:</h5>
                      <b-input-group
                        class="invoice-edit-input-group disabled mw-100"
                      >
                        <flat-pickr
                          id="abrechungszeitraum"
                          v-model="invoiceDateRange"
                          class="w-100 text-right"
                          :config="flatPickrConfig"
                          placeholder="Abrechnungszeitraum festlegen"
                          @input="
                            if (!invoiceData.Manuelle_Rechnung) {
                              getPruefpositionData(invoiceDateRange);
                            }
                          "
                        />
                        <small
                          v-if="invoiceDateRangeOverlapping"
                          class="text-danger"
                          >Der Abrechnungszeitraum überschneidet sich mit
                          folgenden Rechnungen:</small
                        >
                        <small
                          v-if="invoiceDateRangeOverlapping"
                          class="text-danger"
                          >{{ invoiceDateRangeOverlapping }}</small
                        >
                      </b-input-group>
                    </div>

                    <div
                      v-if="!invoiceData.Manuelle_Rechnung"
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="">Ansprechpartner:</h5>
                      <h5>
                        {{ ansprechpartner.Vorname }}
                        {{ ansprechpartner.Nachname }}
                      </h5>
                    </div>
                    <div
                      v-if="!invoiceData.Manuelle_Rechnung"
                      class="d-flex align-items-center justify-content-md-between mb-50"
                    >
                      <h5 class="">Auftragsort:</h5>
                      <h5>
                        {{ auftragsort }}
                      </h5>
                    </div>
                  </b-col>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h6 class="mb-2">
                      {{ Gutschrift ? 'Gutschrift' : 'Rechnung' }} an:
                    </h6>
                    <b-form-group
                      v-if="invoiceData.Manuelle_Rechnung"
                      label="Kunde"
                      :label-for="`manueller-kunde`"
                    >
                      <v-select
                        :id="`manueller-kunde`"
                        v-model="manualClient"
                        :name="`manueller-kunde`"
                        :options="manualClientOptions"
                        :clearable="false"
                        label="Firma"
                        class="item-selector-title"
                        placeholder="Select Item"
                      />
                    </b-form-group>

                    <!-- Rechnungsadresse -->
                    <div
                      v-if="manualClient.Firma || orderData.Kunde.Firma"
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ manualClient.Firma || orderData.Kunde.Firma }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ rechnungsadresse.Strasse }}
                        {{ rechnungsadresse.Hausnummer }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ rechnungsadresse.Zusatz }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ rechnungsadresse.PLZ }}
                        {{ rechnungsadresse.Stadt }}
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(rechnungsposition, index) in rechnungspositionen"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="4">
                            <b-form-group
                              label="Bezeichnung"
                              :label-for="`bezeichnung-${index}`"
                            >
                              <v-select
                                :id="`bezeichnung-${index}`"
                                v-model="rechnungsposition.Bezeichnung"
                                :name="`bezeichnung-${index}`"
                                :options="rechnungspositionOptions"
                                label="Bezeichnung"
                                :clearable="false"
                                class="item-selector-title"
                                placeholder="Select Item"
                                @input="
                                  val => updateRechnungspositionForm(index, val)
                                "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group
                              label="Menge"
                              :label-for="`menge-${index}`"
                            >
                              <b-input-group
                                :append="rechnungsposition.Einheit"
                              >
                                <b-form-input
                                  :id="`menge-${index}`"
                                  v-model="rechnungsposition.Menge"
                                  :name="`menge-${index}`"
                                  type="number"
                                  min="0"
                                  @change="calculateSum(index)"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" lg="2">
                            <b-form-group
                              label="Einzelpreis (Netto)"
                              :label-for="`einzelpreis-${index}`"
                            >
                              <b-input-group append="€">
                                <b-form-input
                                  :id="`einzelpreis-${index}`"
                                  v-model="rechnungsposition.Einzelpreis"
                                  :name="`einzelpreis-${index}`"
                                  type="number"
                                  min="0"
                                  @change="calculateSum(index)"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group
                              label="Zuschlag"
                              :label-for="`zuschlag-${index}`"
                            >
                              <b-input-group append="%">
                                <b-form-input
                                  :id="`zuschlag-${index}`"
                                  v-model="rechnungsposition.Zuschlag"
                                  :name="`zuschlag-${index}`"
                                  type="number"
                                  min="0"
                                  @change="calculateSum(index)"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group
                              label="Gesamt (Netto)"
                              :label-for="`gesamt-${index}`"
                            >
                              <p :id="`gesamt-${index}`" class="mt-1">
                                {{
                                  new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(+rechnungsposition.Gesamt)
                                }}
                              </p>
                            </b-form-group>
                          </b-col>

                          <!-- Kontrolle -->
                          <b-col
                            cols="12"
                            class="mt-2"
                            v-if="rechnungsposition.kontrolleArbeitszeiten"
                          >
                            <b-form-group
                              :label="`Kontrolle Mitarbeiter - ${rechnungsposition.Bezeichnung}`"
                              :label-for="`mitarbeiterleistungen-${index}`"
                            >
                              <b-table
                                :items="
                                  rechnungsposition.kontrolleArbeitszeiten
                                "
                                :fields="kontrolleArbeitszeitenFields"
                                :per-page="100"
                                :current-page="1"
                                sort-by="Datum"
                                striped
                                responsive
                                class="mb-0"
                                :id="`mitarbeiterleistungen-${index}`"
                              >
                                <!-- Column: Mitarbeiter -->
                                <template #cell(Mitarbeiter)="data">
                                  {{ data.item.Mitarbeiter.Vorname }}
                                  {{ data.item.Mitarbeiter.Nachname }}
                                </template>

                                <!-- Column: Datum -->
                                <template #cell(Datum)="data">
                                  {{
                                    new Date(
                                      data.item.Arbeitsbeginn
                                    ).toLocaleDateString('de-DE', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                    })
                                  }}
                                </template>

                                <!-- Column: Arbeitszeit -->
                                <template #cell(Arbeitszeit)="data">
                                  {{
                                    convertToIndustrialHours(
                                      data.item.Arbeitszeit
                                    )
                                  }}
                                  ih
                                </template>
                              </b-table>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeRechnungsposition(index)"
                          />

                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            size="16"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                          />

                          <!-- Setting Item Form -->
                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <b-col cols="12">
                                  <h4>Rechnungsblock anlegen</h4>
                                </b-col>
                                <b-col cols="12">
                                  <b-form-group
                                    label="Bezeichnung"
                                    :label-for="`rechnungsblock-bezeichnung-${index}`"
                                  >
                                    <b-input-group>
                                      <b-form-input
                                        :id="`rechnungsblock-bezeichnung-${index}`"
                                        v-model="newInvoiceBlock.Bezeichnung"
                                        :name="`rechnungsblock-bezeichnung-${index}`"
                                        placeholder="Name des Rechnungsblocks"
                                      />
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <b-form-group
                                    label="Einheit"
                                    :label-for="`rechnungsblock-einheit-${index}`"
                                  >
                                    <b-input-group>
                                      <b-form-input
                                        :id="`rechnungsblock-einheit-${index}`"
                                        v-model="newInvoiceBlock.Einheit"
                                        :name="`rechnungsblock-einheit-${index}`"
                                        placeholder="Stk."
                                      />
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                  <b-form-group
                                    label="Einzelpreis"
                                    :label-for="`rechnungsblock-einzelpreis-${index}`"
                                  >
                                    <b-input-group append="€">
                                      <b-form-input
                                        :id="`rechnungsblock-einzelpreis-${index}`"
                                        v-model.number="
                                          newInvoiceBlock.Einzelpreis
                                        "
                                        :name="`rechnungsblock-einzelpreis-${index}`"
                                        type="number"
                                        min="0"
                                      />
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                  <b-form-group
                                    label="Zuschlag"
                                    :label-for="`rechnungsblock-zuschlag-${index}`"
                                  >
                                    <b-input-group append="%">
                                      <b-form-input
                                        :id="`rechnungsblock-zuschlag-${index}`"
                                        v-model.number="
                                          newInvoiceBlock.Zuschlag
                                        "
                                        :name="`rechnungsblock-zuschlag-${index}`"
                                        type="number"
                                        min="0"
                                      />
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="primary"
                                    @click="applyInvoiceBlock(index)"
                                  >
                                    Anwenden
                                  </b-button>
                                  <b-button
                                    variant="primary"
                                    @click="updateInvoiceBlocks(index)"
                                  >
                                    Speichern
                                  </b-button>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-center mt-1"
                                >
                                  <b-button
                                    variant="outline-primary"
                                    class="w-100"
                                    @click="
                                      () => {
                                        $refs[
                                          `form-item-settings-popover-${index}`
                                        ][0].$emit('close');
                                      }
                                    "
                                  >
                                    Schließen
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="">
                  <b-col cols="6" class="align-self-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addNewRechnungsposition"
                    >
                      Position hinzufügen
                    </b-button>
                  </b-col>
                  <b-col cols="6" class="d-flex flow-row justify-content-end">
                    <b-form-group
                      label="Rabatt"
                      :label-for="`rabatt`"
                      class="mx-1"
                    >
                      <b-input-group append="%">
                        <b-form-input
                          :id="`rabatt`"
                          v-model.number="rabatt"
                          :name="`rabatt`"
                          type="number"
                          min="0"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Skonto" :label-for="`skonto`">
                      <b-input-group append="%">
                        <b-form-input
                          :id="`skonto`"
                          v-model.number="skonto"
                          :name="`skonto`"
                          type="number"
                          min="0"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 mb-3">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Netto:</p>
                        <p class="invoice-total-amount">
                          {{
                            new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalSums.netSum)
                          }}
                        </p>
                      </div>
                      <div
                        v-if="totalSums.rabatt > 0"
                        class="invoice-total-item"
                      >
                        <p class="invoice-total-title">Rabatt:</p>
                        <p class="invoice-total-amount">
                          -{{
                            new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalSums.rabatt)
                          }}
                        </p>
                      </div>
                      <div
                        v-if="totalSums.skonto > 0"
                        class="invoice-total-item"
                      >
                        <p class="invoice-total-title">Skonto:</p>
                        <p class="invoice-total-amount">
                          -{{
                            new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalSums.skonto)
                          }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">MwSt. 19%:</p>
                        <p class="invoice-total-amount">
                          {{
                            new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalSums.tax)
                          }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Gesamt:</p>
                        <p class="invoice-total-amount">
                          {{
                            new Intl.NumberFormat('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalSums.totalSum)
                          }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Save Invoice Draft -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="noRechnungspositions"
            @click="
              $router.push(
                invoiceData.Manuelle_Rechnung
                  ? {
                      name: 'manuelle-rechnung-vorschau',
                      params: { id: invoiceData.id },
                    }
                  : {
                      name: 'rechnung-vorschau',
                      params: { id: invoiceData.id },
                    }
              )
            "
          >
            Abbrechen
          </b-button>
          <hr />
          <!-- Button: Save Invoice Draft -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="noRechnungspositions"
            @click="updateInvoice()"
          >
            Speichern
          </b-button>
          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            block
            @click="deleteInvoice()"
          >
            Löschen
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@/layouts/components/TempoRatioLogo.vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BPopover,
  BTable,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';

localize('de', de);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BPopover,
    BTable,
    flatPickr,
    vSelect,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      invoiceDateRange: '',
      invoiceDateRangeOverlapping: false,
      invoiceBlocks: [],
      newInvoiceBlock: { Einzelpreis: 0, Zuschlag: 0 },
      flatPickrConfig: {
        mode: 'range',
        dateFormat: 'Y-m-dTH:i:S.000',
        altInput: true,
        altFormat: 'd.m.Y',
        allowInput: true,
        time_24hr: true,
        locale: German,
      },
      skonto: 0,
      rabatt: 0,
      orderData: {
        Auftragsnummer: null,
        Ansprechpartner_ID: 0,
        Kunde: {
          Firma: null,
          Anschrift: {
            Stadt: null,
          },
          Ansprechpartner: [
            {
              id: 0,
              Vorname: null,
              Nachname: null,
            },
          ],
        },
      },
      invoiceData: {},
      pruefpositionen: [],
      pruefpositionsIds: [],
      rechnungspositionen: [],
      manualClient: {},
      manualClients: [],
      Gutschrift: false,
      kontrolleArbeitszeitenFields: [
        {
          key: 'Mitarbeiter',
          label: 'Mitarbeiter',
          sortable: true,
        },
        {
          key: 'Datum',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'Arbeitszeit',
          label: 'Arbeitszeit',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    totalSums() {
      let netSum = 0;

      if (this.rechnungspositionen) {
        this.rechnungspositionen.forEach(rechnungsposition => {
          netSum += rechnungsposition.Gesamt;
        });
      }

      const rabatt = netSum * (this.rabatt / 100);
      const skonto = (netSum - rabatt) * (this.skonto / 100);
      const tax = (netSum - rabatt - skonto) * 0.19;
      const totalSum = netSum - rabatt - skonto + tax;

      return {
        netSum,
        rabatt,
        skonto,
        tax,
        totalSum,
      };
    },
    auftragsort() {
      return this.orderData.Abweichender_Auftragsort?.Stadt &&
        this.orderData.Abweichender_Auftragsort.Stadt !== '-'
        ? this.orderData.Abweichender_Auftragsort.Stadt
        : this.orderData.Kunde.Anschrift.Stadt;
    },
    ansprechpartner() {
      return this.orderData.Kunde.Ansprechpartner.filter(
        ansprechpartner =>
          ansprechpartner.id === this.orderData.Ansprechpartner_ID
      )[0];
    },
    rechnungsadresse() {
      switch (true) {
        case this.invoiceData.Manuelle_Rechnung &&
          this.manualClient.Rechnungsanschrift !== null:
          return this.manualClient.Rechnungsanschrift;
        case this.invoiceData.Manuelle_Rechnung &&
          this.manualClient.Rechnungsanschrift === null:
          return this.manualClient.Anschrift;
        case this.orderData.Kunde.Rechnungsanschrift !== null:
          return this.orderData.Kunde.Rechnungsanschrift;
        case !this.orderData.Kunde.Rechnungsanschrift === null:
          return this.orderData.Kunde.Anschrift;
        default:
          return this.orderData.Kunde.Anschrift;
      }
    },
    noRechnungspositions() {
      return this.rechnungspositionen.length === 0;
    },
    rechnungspositionOptions() {
      return [
        {
          Bezeichnung: 'Verwaltungskosten',
          Einheit: 'Pausch.',
          Einzelpreis: 150,
          Zuschlag: 0,
        },
        {
          Bezeichnung: 'An- und Abfahrten',
          Einheit: 'Tage',
          Einzelpreis: this.invoiceData.Manuelle_Rechnung
            ? 30
            : this.orderData.Auftragskonditionen.Anfahrtskosten,
          Zuschlag: 0,
        },
        ...this.invoiceBlocks,
      ];
    },
    manualClientOptions() {
      return this.manualClients;
    },
  },
  watch: {
    'invoiceData.Rechnungsnummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Rechnungsnummer: {
                $eq: this.invoiceData.Rechnungsnummer,
              },
            },
            fields: ['id', 'Rechnungsnummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/rechnungen?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.invoiceIdNotUnique = true;
            } else {
              this.invoiceIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    async invoiceDateRange(newValue, oldValue) {
      if (newValue !== oldValue && !this.invoiceData.Manuelle_Rechnung) {
        const [startDateString, endDateString] = newValue.split(' bis ');
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const qs = require('qs');
        const query = qs.stringify(
          {
            fields: ['id'],
            populate: {
              Rechnungen: {
                fields: [
                  'Abrechnungszeitraum_Start',
                  'Abrechnungszeitraum_Ende',
                  'Rechnungsnummer',
                ],
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/auftraege/${this.invoiceData.Auftrag.id}?${query}`)
          .then(response => {
            this.invoiceDateRangeOverlapping = false;
            const overlappingInvoices = [];
            const invoices = response.data.data.Rechnungen;
            invoices.forEach(invoice => {
              if (invoice.id != this.invoiceData.id) {
                const invoiceStartDate = new Date(
                  invoice.Abrechnungszeitraum_Start
                );
                const invoiceEndDate = new Date(
                  invoice.Abrechnungszeitraum_Ende
                );
                if (
                  (startDate < invoiceEndDate && endDate > invoiceStartDate) ||
                  startDate === invoiceStartDate ||
                  (startDate > invoiceStartDate &&
                    startDate < invoiceEndDate) ||
                  (invoiceStartDate > startDate && invoiceStartDate < endDate)
                ) {
                  overlappingInvoices.push(invoice.Rechnungsnummer);
                }
              }
            });
            if (overlappingInvoices.length > 0) {
              this.invoiceDateRangeOverlapping = `${overlappingInvoices.join(
                ', '
              )}`;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.params.gutschrift) {
      to.meta.pageTitle = 'Rechnung bearbeiten';
      to.meta.breadcrumb[1] = {
        text: 'Rechnung bearbeiten',
        active: true,
      };
    }
    if (to.params.gutschrift) {
      to.meta.pageTitle = 'Gutschrift bearbeiten';
      to.meta.breadcrumb[1] = {
        text: 'Gutschrift nbearbeiten',
        active: true,
      };
    }
    if (!to.params.manualInvoice) {
      // Initialize Breadcrumb Text
      to.meta.breadcrumb[1] = {
        text: 'Auftrag',
        active: true,
      };
    }
    next();
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight);

    await this.getInvoiceData();

    this.initTrHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    convertToIndustrialHours(timeString) {
      const timeParts = timeString.split(':');
      const hours = +timeParts[0];
      const minutes = (+timeParts[1] / 60) * 100;
      return `${hours},${minutes}`;
    },
    calculateSum(index) {
      const rechnungsposition = this.rechnungspositionen[index];
      rechnungsposition.Gesamt =
        rechnungsposition.Menge *
        rechnungsposition.Einzelpreis *
        (1 + rechnungsposition.Zuschlag / 100);
      this.rechnungspositionen[index].Gesamt = rechnungsposition.Gesamt;
    },
    setRechnungspositionen(pruefpositionen) {
      this.rechnungspositionen = [];
      this.pruefpositionsIds = [];

      const combinedData = {
        Arbeitszeit: 0,
        Arbeitszeit_Samstag: 0,
        Arbeitszeit_Wochenende: 0,
        Arbeitszeit_Nachtschicht: 0,
        Fahrten: 0,
      };
      const kontrolle = {
        Arbeitszeit: [],
        Arbeitszeit_Samstag: [],
        Arbeitszeit_Wochenende: [],
        Arbeitszeit_Nachtschicht: [],
      };

      pruefpositionen.forEach(pruefposition => {
        if (pruefposition.Mitarbeiterleistungen.length === 0) {
          return;
        }
        this.pruefpositionsIds.push({ id: pruefposition.id });
        combinedData.Fahrten += pruefposition.Mitarbeiterleistungen.length;

        const addArbeitszeit = (mitarbeiterleistung, property) => {
          if (mitarbeiterleistung[property]) {
            kontrolle[property].push({
              Mitarbeiter: mitarbeiterleistung.Mitarbeiter,
              Arbeitszeit: mitarbeiterleistung[property],
              Arbeitsbeginn: mitarbeiterleistung.Arbeitsbeginn,
            });
            let parts = mitarbeiterleistung[property].split(':');
            if (parts.length !== 3 || isNaN(parts[0]) || isNaN(parts[1])) {
              console.error(`Invalid format for ${property}`);
              return;
            }

            combinedData[property] += +parts[0] + +parts[1] / 60;
            combinedData[property] =
              Math.round(combinedData[property] * 100) / 100;
          }
        };

        const properties = [
          'Arbeitszeit',
          'Arbeitszeit_Samstag',
          'Arbeitszeit_Wochenende',
          'Arbeitszeit_Nachtschicht',
        ];

        pruefposition.Mitarbeiterleistungen.forEach(mitarbeiterleistung => {
          properties.forEach(property =>
            addArbeitszeit(mitarbeiterleistung, property)
          );
        });
      });

      if (combinedData.Fahrten > 0) {
        this.rechnungspositionen.push({
          Bezeichnung: 'An- und Abfahrten',
          Einheit: 'Tage',
          Einzelpreis: this.orderData.Auftragskonditionen.Anfahrtskosten || 30,
          Zuschlag: 0,
          Menge: combinedData.Fahrten,
          Gesamt:
            combinedData.Fahrten *
            (this.orderData.Auftragskonditionen.Anfahrtskosten || 30) *
            (1 + 0 / 100),
        });
      }

      if (combinedData.Arbeitszeit > 0) {
        this.rechnungspositionen.push({
          Bezeichnung: 'Stunden Früh- / Spätschicht',
          Einheit: 'Std.',
          Einzelpreis: this.orderData.Auftragskonditionen.Stundensatz || 30,
          Zuschlag: 0,
          Menge: combinedData.Arbeitszeit,
          Gesamt:
            combinedData.Arbeitszeit *
            (this.orderData.Auftragskonditionen.Stundensatz || 30) *
            (1 + 0 / 100),
          kontrolleArbeitszeiten: kontrolle.Arbeitszeit,
        });
      }

      if (combinedData.Arbeitszeit_Nachtschicht > 0) {
        this.rechnungspositionen.push({
          Bezeichnung: 'Stunden Nachtschicht',
          Einheit: 'Std.',
          Einzelpreis: this.orderData.Auftragskonditionen.Stundensatz || 30,
          Zuschlag: 25,
          Menge: combinedData.Arbeitszeit_Nachtschicht,
          Gesamt:
            combinedData.Arbeitszeit_Nachtschicht *
            (this.orderData.Auftragskonditionen.Stundensatz || 30) *
            (1 + 25 / 100),
          kontrolleArbeitszeiten: kontrolle.Arbeitszeit_Nachtschicht,
        });
      }

      if (combinedData.Arbeitszeit_Samstag > 0) {
        this.rechnungspositionen.push({
          Bezeichnung: 'Stunden Samstag',
          Einheit: 'Std.',
          Einzelpreis: this.orderData.Auftragskonditionen.Stundensatz || 30,
          Zuschlag: 50,
          Menge: combinedData.Arbeitszeit_Samstag,
          Gesamt:
            combinedData.Arbeitszeit_Samstag *
            (this.orderData.Auftragskonditionen.Stundensatz || 30) *
            (1 + 50 / 100),
          kontrolleArbeitszeiten: kontrolle.Arbeitszeit_Samstag,
        });
      }

      if (combinedData.Arbeitszeit_Wochenende > 0) {
        this.rechnungspositionen.push({
          Bezeichnung: 'Stunden Sonntag / Feiertag',
          Einheit: 'Std.',
          Einzelpreis: this.orderData.Auftragskonditionen.Stundensatz || 30,
          Zuschlag: 50,
          Menge: combinedData.Arbeitszeit_Wochenende,
          Gesamt:
            combinedData.Arbeitszeit_Wochenende *
            (this.orderData.Auftragskonditionen.Stundensatz || 30) *
            (1 + 50 / 100),
          kontrolleArbeitszeiten: kontrolle.Arbeitszeit_Wochenende,
        });
      }

      this.rechnungspositionen.push({
        Bezeichnung: 'Verwaltungskosten',
        Einheit: 'Pausch.',
        Einzelpreis: 150,
        Zuschlag: 0,
        Menge: 1,
        Gesamt: 1 * 150 * (1 + 0 / 100),
      });
    },
    addNewRechnungsposition() {
      this.$refs.form.style.overflow = 'hidden';
      this.rechnungspositionen.push({
        Menge: 0,
        Einheit: 'Std.',
        Einzelpreis: 0,
        Zuschlag: 0,
        Gesamt: 0,
        Bezeichnung: 'Stunden Früh- / Spätschicht',
        Pruefposition: null,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    updateRechnungspositionForm(index, val) {
      const { Einheit, Einzelpreis, Zuschlag } = val;
      this.rechnungspositionen[index].Einheit = Einheit;
      this.rechnungspositionen[index].Einzelpreis = Einzelpreis;
      this.rechnungspositionen[index].Zuschlag = Zuschlag;
      this.calculateSum(index);
    },
    removeRechnungsposition(index) {
      this.rechnungspositionen.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    applyInvoiceBlock(index) {
      if (this.newInvoiceBlock.Bezeichnung && this.newInvoiceBlock.Einheit) {
        this.invoiceBlocks.push(this.newInvoiceBlock);

        const { Bezeichnung, Einheit, Einzelpreis, Zuschlag } =
          this.newInvoiceBlock;
        this.rechnungspositionen[index].Bezeichnung = Bezeichnung;
        this.rechnungspositionen[index].Einheit = Einheit;
        this.rechnungspositionen[index].Einzelpreis = Einzelpreis;
        this.rechnungspositionen[index].Zuschlag = Zuschlag;
        this.calculateSum(index);

        this.newInvoiceBlock = { Einzelpreis: 0, Zuschlag: 0 };
        this.$refs[`form-item-settings-popover-${index}`][0].$emit('close');
        return true;
      }
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Eingabe nicht korrekt!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Bei der Eingabe des Positionsblocks fehlt entweder die Bezeichnung oder die Einheit. Bitte überprüfen Sie Ihre Eingabe.',
        },
      });
      this.$refs[`form-item-settings-popover-${index}`][0].$emit('close');
      return false;
    },
    async updateInvoiceBlocks(index) {
      if (this.applyInvoiceBlock(index)) {
        await this.$http
          .put('/rechnungsbloecke', {
            data: { Rechnungsblock: this.invoiceBlocks },
          })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Positionsblock gespeichert',
                icon: 'SaveIcon',
                variant: 'success',
                text: `Der Positionsblock "${
                  this.invoiceBlocks[this.invoiceBlocks.length - 1].Bezeichnung
                }" wurde erfolgreich gespeichert.`,
              },
            });
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Speichern des Positionsblocks fehlgeschlagen!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Der Positionsblock konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.',
              },
            });
            console.log(err.response.data.error.message);
          });
      }
    },
    async getInvoiceBlocks() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id'],
          populate: ['Rechnungsblock'],
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/rechnungsbloecke?${query}`)
        .then(response => {
          this.invoiceBlocks = response.data.data.Rechnungsblock;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async getOrderData() {
      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id', 'Status', 'Auftragsnummer', 'Ansprechpartner_ID'],
          populate: {
            Auftragskonditionen: {
              fields: ['Anfahrtskosten', 'Startdatum', 'Stundensatz'],
            },
            Abweichender_Auftragsort: {
              fields: ['Stadt'],
            },
            Rechnungen: {
              fields: 'id',
            },
            Kunde: {
              fields: ['Firma', 'Kundennummer', 'Zahlungsziel'],
              populate: {
                Ansprechpartner: {
                  fields: ['Vorname', 'Nachname'],
                },
                Anschrift: {
                  fields: ['*'],
                },
                Rechnungsanschrift: {
                  fields: ['*'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege/${this.invoiceData.Auftrag.id}?${query}`)
        .then(response => {
          this.orderData = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async getPruefpositionData(dateRange) {
      let [startDate, endDate] = dateRange.split(' bis ');
      if (!endDate) {
        endDate = startDate;
      }
      if (!startDate) {
        startDate = endDate;
      }
      const modifiedEndDate = new Date(endDate);
      endDate = new Date(
        modifiedEndDate.setDate(modifiedEndDate.getDate() + 1)
      );

      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id'],
          populate: {
            Pruefpositionen: {
              fields: ['Bezeichnung', 'Status'],
              populate: {
                Mitarbeiterleistungen: {
                  filters: {
                    $and: [
                      {
                        Arbeitsbeginn: {
                          $gte: startDate,
                        },
                      },
                      {
                        Arbeitsbeginn: {
                          $lte: endDate,
                        },
                      },
                    ],
                  },
                  fields: [
                    'Arbeitsbeginn',
                    'Arbeitszeit',
                    'Arbeitszeit_Nachtschicht',
                    'Arbeitszeit_Samstag',
                    'Arbeitszeit_Wochenende',
                  ],
                  populate: {
                    Mitarbeiter: {
                      fields: ['Vorname', 'Nachname'],
                    },
                  },
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege/${this.invoiceData.Auftrag.id}?${query}`)
        .then(response => {
          this.pruefpositionen = response.data.data.Pruefpositionen;
          this.setRechnungspositionen(this.pruefpositionen);
        })
        .catch(err => console.log(err.response.data.error.message));
      this.initTrHeight();
    },
    async getManualClientData() {
      // Fetch Client Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['Firma', 'Kundennummer', 'Zahlungsziel'],
          populate: {
            Ansprechpartner: {
              fields: ['Vorname', 'Nachname'],
            },
            Anschrift: {
              fields: ['*'],
            },
            Rechnungsanschrift: {
              fields: ['*'],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/kunden?${query}`)
        .then(response => {
          this.manualClients = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async getInvoiceData() {
      // Fetch Bericht Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: '*',
          populate: {
            Auftrag: {
              fields: ['Auftragsnummer', 'Ansprechpartner_ID'],
              populate: {
                Kunde: {
                  fields: ['Firma', 'Kundennummer'],
                  populate: {
                    Anschrift: {
                      fields: ['*'],
                    },
                    Rechnungsanschrift: {
                      fields: ['*'],
                    },
                    Ansprechpartner: {
                      fields: ['Vorname', 'Nachname'],
                    },
                  },
                },
                Abweichender_Auftragsort: {
                  fields: ['*'],
                },
              },
            },
            Rechnungspositionen: {
              fields: '*',
            },
            Manueller_Kunde: {
              fields: '*',
              populate: {
                Kunde: {
                  fields: ['Firma', 'Kundennummer'],
                  populate: {
                    Anschrift: {
                      fields: '*',
                    },
                    Rechnungsanschrift: {
                      fields: '*',
                    },
                  },
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/rechnungen/${this.$route.params.id}?${query}`)
        .then(async response => {
          this.invoiceData = response.data.data;
          this.Gutschrift = this.invoiceData.Gutschrift;
          this.invoiceDateRange = `${
            this.invoiceData.Abrechnungszeitraum_Start
          }${
            this.invoiceData.Abrechnungszeitraum_Ende
              ? ` bis ${this.invoiceData.Abrechnungszeitraum_Ende}`
              : ''
          }`;

          if (this.invoiceData.Manuelle_Rechnung) {
            this.manualClient = this.invoiceData.Manueller_Kunde.Kunde;
            await this.getManualClientData();
            // Change Breadcrumb Text
            this.$route.meta.breadcrumb[1] = {
              text: `${this.Gutschrift ? 'Gutschrift' : 'Rechnung'} bearbeiten`,
              active: true,
            };
          }

          if (!this.invoiceData.Manuelle_Rechnung) {
            await this.getOrderData();
            // Change Breadcrumb Text
            this.$route.meta.breadcrumb[1] = {
              text: `Auftrag #${this.orderData.Auftragsnummer}`,
              to: { path: `/auftrag/details/${this.orderData.id}` },
            };
          }
          // Change Page Text
          this.$route.meta.pageTitle = `${
            this.Gutschrift ? 'Gutschrift' : 'Rechnung'
          } #${this.invoiceData.Rechnungsnummer} bearbeiten`;
          this.$router.replace({ query: { temp: Date.now() } });
          this.$router.replace({ query: { temp: undefined } });
          await this.getInvoiceBlocks();
          this.rechnungspositionen = this.invoiceData.Rechnungspositionen;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async updateInvoice() {
      this.$refs.registerInvoiceForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Eingabe nicht korrekt!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.',
            },
          });
          return;
        }

        this.rechnungspositionen.forEach((rechnungsposition, index) => {
          if (typeof rechnungsposition.Bezeichnung === 'object') {
            this.rechnungspositionen[index].Bezeichnung =
              rechnungsposition.Bezeichnung.Bezeichnung;
          }
        });

        const data = {
          ...this.invoiceData,
          ...(!this.invoiceData.Manuelle_Rechnung && {
            Ansprechpartner_ID: this.orderData.Ansprechpartner_ID,
          }),
          Rechnungspositionen: this.rechnungspositionen,
          ...(!this.invoiceData.Manuelle_Rechnung && {
            Auftrag: this.orderData.id,
          }),
          ...(!this.invoiceData.Manuelle_Rechnung && {
            Pruefpositionen: this.pruefpositionsIds,
          }),
          ...(this.invoiceData.Manuelle_Rechnung && {
            Manueller_Kunde: { Kunde: { id: this.manualClient.id } },
          }),
          Rechnungsbetrag: this.totalSums.totalSum,
          Gutschrift: this.Gutschrift,
          Gespeicherte_Rechnungsdaten: JSON.stringify({
            Skonto: this.skonto,
            Rabatt: this.rabatt,
            Skonto_Betrag: this.totalSums.skonto,
            Rabatt_Betrag: this.totalSums.rabatt,
            Netto_Betrag: this.totalSums.netSum,
            Steuer_Betrag: this.totalSums.tax,
          }),
        };

        [data.Abrechnungszeitraum_Start, data.Abrechnungszeitraum_Ende] =
          this.invoiceDateRange.split(' bis ');

        await this.$http
          .put(`/rechnungen/${this.$route.params.id}`, { data })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${
                  this.Gutschrift ? 'Gutschrift' : 'Rechnung'
                } aktualisiert`,
                icon: 'SaveIcon',
                variant: 'success',
                text: `Die ${this.Gutschrift ? 'Gutschrift' : 'Rechnung'} #${
                  this.invoiceData.Rechnungsnummer
                } wurde erfolgreich aktualisiert.`,
              },
            });
            this.$router.push(
              this.invoiceData.Manuelle_Rechnung
                ? {
                    name: 'manuelle-rechnung-vorschau',
                    params: { id: response.data.data.id },
                  }
                : {
                    name: 'rechnung-vorschau',
                    params: { id: response.data.data.id },
                  }
            );
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Anlegen der ${
                  this.Gutschrift ? 'Gutschrift' : 'Rechnung'
                } fehlgeschlagen!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Die ${
                  this.Gutschrift ? 'Gutschrift' : 'Rechnung'
                } konnte nicht aktualisiert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
              },
            });
            console.log(err.response.data.error.message);
          });
      });
    },
    async deleteInvoice() {
      await this.$http
        .delete(`/rechnungen/${this.$route.params.id}`)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.Gutschrift ? 'Gutschrift' : 'Rechnung'} gelöscht`,
              icon: 'SaveIcon',
              variant: 'success',
              text: `Die ${this.Gutschrift ? 'Gutschrift' : 'Rechnung'} #${
                this.invoiceData.Rechnungsnummer
              } wurde erfolgreich gelöscht.`,
            },
          });
          this.$router.push(
            this.invoiceData.Manuelle_Rechnung
              ? {
                  name: 'rechnungen',
                }
              : {
                  name: 'auftrag-details',
                  params: { id: this.orderData.id },
                }
          );
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Löschen der ${
                this.Gutschrift ? 'Gutschrift' : 'Rechnung'
              } fehlgeschlagen!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die ${
                this.Gutschrift ? 'Gutschrift' : 'Rechnung'
              } konnte nicht gelöscht werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
            },
          });
          console.log(err.response.data.error.message);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#abrechungszeitraum + .form-control.input {
  text-align: end;
  border-radius: 0.3rem;
}
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
